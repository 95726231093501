import { getAccessToken, getCustomerId } from "../../_actions";
import { getTenantId } from "../../_actions/appConfig.actions";
import { store } from "../../_store";

const IoTPlatformUtils = {    
    createIoTPlatformHeaders(tenantUserId) {

        if (!tenantUserId) {
            throw new Error('tenantUserId is required for IoT Platform headers');
        }

        const accessToken = getAccessToken();
        const customerId = getCustomerId();
        const tenantId = getTenantId();
        const userPin = store.getState().onboardingReducer.userPin;

        // Validate required values
        if (!accessToken) throw new Error('Authorization token is missing');
        if (!customerId) throw new Error('Customer ID is missing');
        if (!tenantId) throw new Error('Tenant ID is missing');
        if (!userPin) throw new Error('User PIN is missing');

        return {
            'Authorization': accessToken,
            "Content-Type": "application/json",
            "X-user-os": "IOS",
            "x-pin": userPin,
            "x-customer-id": customerId,      
            'X-tenant-id': tenantId,
            'X-tenant-user-id': tenantUserId,
        }
    },
}

export default IoTPlatformUtils;