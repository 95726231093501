import {
  setAccessToken,
  setAgeVerified,
  setDoneVault,
  setUserData,
  setUserEverLogged,
  userActions,
} from "../_actions";
import {
  getCountry,
  getNumMaxDevices,
  getSupportedServices,
  isLightMarket,
  isSupportedService,
} from "../_actions/appConfig.actions";
import {
  crmUserSettings,
  routingConstants,
  servicesConstants,
} from "../_constants";
import { store } from "../_store";

export class Tenants {
  static getAfterRegistrationView(payload) {
    console.log("[getAfterRegistrationView]", payload);
    const country = getCountry();
    console.log("[getAfterRegistrationView] country", country);
    let viewUrl = null;
    if (country) {
      switch (country) {
        case "CA":
        case "ZA":
        case "DM": {
          const userToken = payload.token;
          const userData = payload.user;

          setAccessToken(userToken);
          setUserData(userData);
          setUserEverLogged(null);
          setDoneVault(false);

          //dispatch crm setting
          store.dispatch(
            userActions.setUserSettings(
              crmUserSettings.COOKIE_NOTICE,
              (
                store.getState().onboardingReducer.acceptedCookies === true
              ).toString()
            )
          );
          store.dispatch(
            userActions.setUserSettings(crmUserSettings.PRIVACY_POLICY, "true")
          );

          store.dispatch(
            userActions.setUserSettings(
              crmUserSettings.OPT_IN_EMAIL,
              payload.user?.userOptedInForEmails
            )
          );

          store.dispatch(
            userActions.setUserSettings(
              crmUserSettings.OPT_IN_NOTIFICATIONS,
              payload.user?.userOptedInForPushNotifications
            )
          );

          if (isSupportedService(servicesConstants.MARKETING_OPTIN_SMS)) {
            store.dispatch(
              userActions.setUserSettings(
                crmUserSettings.OPT_IN_SMS,
                payload.user?.userOptedInForSMS
              )
            );
          }

          const supportedServices = getSupportedServices();

          if (
            this.isGlobal() &&
            supportedServices.includes(servicesConstants.ONBOARDING_AV_YOTI) &&
            !userData?.isUserAgeVerified
          ) {
            viewUrl = routingConstants.HARD_AGE_VERIFICATION;
          } else {
            viewUrl = routingConstants.TERMS_AND_CONDITIONS;
          }

          break;
        }
        case "UK": {
          viewUrl = routingConstants.BENEFITS;
          break;
        }
        default: {
          viewUrl = routingConstants.BENEFITS;
        }
      }
    }
    return viewUrl;
  }

  static getAfterCountrySelectionView() {
    const country = getCountry();
    const isLight = isLightMarket();
    let viewUrl = null;
    if (country) {
      switch (country) {
        case "CA": {
          if (getSupportedServices().includes(servicesConstants.ONBOARDING_SOFT_AGE_VERIFICATION)) {
            viewUrl = routingConstants.SOFT_AGE_VERIFICATION;
          } else {
            setAgeVerified(true);
            viewUrl = routingConstants.BENEFITS;
          }
          break;
        }
        case "UK": {
          viewUrl = routingConstants.SOFT_AGE_VERIFICATION;
          break;
        }
        default: {
          viewUrl = routingConstants.SOFT_AGE_VERIFICATION;
        }
      }
    }
    return viewUrl;
  }

  static isCanadaLight() {
    return getCountry() === "CA" && isLightMarket();
  }
  static isCanadaDark() {
    return getCountry() === "CA" && !isLightMarket();
  }
  static isGlobal() {
    return getCountry() !== "CA" && getCountry() !== "DM";
  }
  static isDemo() {
    return getCountry() === "DM";
  }
  static isSouthAfrica() {
    return getCountry() === "ZA";
  }

  static getMaxDevicesLimit() {
    return parseInt(getNumMaxDevices());
  }
}
