import cx from "classnames";
import { isNil } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { deviceActions, userActions } from "../../_actions";
import {
  CustomSlidingPanel,
  DeviceCustomColor,
  DeviceCustomName,
  DeviceLedBrightness,
  DeviceScreenBrightness,
  DeviceVolume,
  DeviceHaptic,
  Header,
  PageSelectorCTA,
  SpinnerModal,
} from "../../_components";
import { FirmwareUpdate } from "../../_components/FirmwareUpdate/FirmwareUpdate";
import {
  AnalyticsEvents,
  AnalyticsFieldNames,
  AnalyticsTargetEventsNames,
  PROFILE_SMARTBOX,
  VUSE_DEVICE,
  aemPages,
  crmUserSettings,
  routingConstants
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import {
  logAnalyticsEvent
} from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import { debug } from "../../_helpers/debug";
import {
  getDeviceInstanceFromSN,
  getDeviceThingFromSN,
  isAdvertisingNameSupported,
  isSynchronized
} from "../../_helpers/device";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { DeviceModel } from "../../_models";
import { dashboardServices } from "../../_services";
import { store } from "../../_store";
import { RemoveDevice } from "../RemoveDevice";
import { getVapingServiceSupport } from "../../_actions/appConfig.actions";
import HMAC_SHA256 from "crypto-js/hmac-sha256";

const MIN_SCREEN_BRIGHTNESS = 25

class DeviceSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDevice: null,
      deviceName: null,
      deviceColor: null,
      ledBrightness: null,
      isShownFirmwareUpgradePanel: false,
      deviceProps: null,
      loading: false,
      isShownRemoveDevicePanel: false,
      isAdvNameSupported: false,
      isSaveSettingsPanelOpen: false,
      saveSettingsError: null,
      spinnerLoading: false,
      spinnerError: false,
      screenBrightness: false,
      haptic: false,
      volume: false,
      loadingSettings: false
    };

    this.startLoading = this._startLoading.bind(this);
    this.stopLoading = this._stopLoading.bind(this);
    this.toggleLoadingWhenSuccess = this._toggleLoadingWhenSuccess.bind(this);
    this.goToDashboard = this._goToDashboard.bind(this);
    this.goToFirmwareUpgrade = this._goToFirmwareUpgrade.bind(this);
    this.goToAdvancedSettings = this._goToAdvancedSettings.bind(this);
    this.toggleFirmwareUpgradePanel =
      this._toggleFirmwareUpgradePanel.bind(this);
    this.hideFirmwareUpdatePanel = this.hideFirmwareUpdatePanel.bind(this);
    this.updateFirmwareProperties = this._updateFirmwareProperties.bind(this);
    this.doDeviceRemoval = this._doDeviceRemoval.bind(this);
    this.onEndDeviceRemoval = this._onEndDeviceRemoval.bind(this);
    this.goToManageDevices = this._goToManageDevices.bind(this);
    this.handleContinue = this._handleContinue.bind(this);

    this.aem = new AEMHelper();
    this.dictionary = {
      ...this.aem.getDictionary(aemPages.DEVICE_SETTINGS, {
        DEVICE_SETT_HEADER: propertyHeadingDefaultEmpty,
        DEVICE_SETT_LED_BRIGHTNESS: propertyTextDefaultEmpty,
        DEVICE_SETT_BTN_SAVE_CHANGES: propertyCtaItemsDefaultEmpty,
        DEVICE_SETT_ADVANCED_SETTINGS_HEADER: propertyHeadingDefaultEmpty,
        DEVICE_SETT_BTN_FIRMWARE_UP_TO_DATE: propertyCtaItemsDefaultEmpty,
        DEVICE_SETT_NEED_HELP_CONTENT: propertyTextDefaultEmpty,
        DEVICE_SETT_FIRMWARE: propertyTextDefaultEmptyParsed,
        DEVICE_SETT_BTN_FIRMWARE_UPDATE: propertyCtaItemsDefaultEmpty,
        DEVICE_SETT_DEVICE_COLOUR: propertyTextDefaultEmpty,
        DEVICE_SETT_DEVICE_NAME: propertyTextDefaultEmpty,
        DEVICE_SETT_REMOVE_DEVICE_TITLE: propertyTextDefaultEmpty,
        DEVICE_SETT_REMOVE_DEVICE_BODY: propertyTextDefaultEmpty,
        DEVICE_SETT_BTN_REMOVE_DEVICE: propertyCtaItemsDefaultEmpty,
        DEVICE_SETT_ADV_NAME_ALERT_TITLE: propertyTextDefaultEmpty,
        DEVICE_SETT_ADV_NAME_ALERT_SUBTITLE: propertyTextDefaultEmptyParsed,
        DEVICE_SETT_ADV_NAME_EMPTY_ERROR: propertyTextDefaultEmptyParsed,
        DEVICE_SETT_ADV_NAME_CHARACTERS_ERROR: propertyTextDefaultEmptyParsed,
        DEVICE_SETT_ADV_NAME_DEVICE_SETTINGS_SUCCESS: propertyTextDefaultEmpty,
        DEVICE_SETT_ADV_NAME_DEVICE_SETTINGS_FAIL: propertyTextDefaultEmpty,
        DEVICE_SETT_ADV_NAME_BTN_CONTINUE: propertyCtaItemsDefaultEmpty,
        DEVICE_SETT_BRIGHTNESS: propertyTextDefaultEmpty,
        DEVICE_SETT_HAPTIC_ALERTS: propertyTextDefaultEmpty,
        DEVICE_SETT_SOUND_ALERT: propertyTextDefaultEmpty,
        DEVICE_SETT_SOFT: propertyTextDefaultEmpty,
        DEVICE_SETT_MEDIUM: propertyTextDefaultEmpty,
        DEVICE_SETT_STRONG: propertyTextDefaultEmpty
      }),
      ...this.aem.getDictionary(aemPages.FW_UPDATE, {
        FW_UPDATE_HEADER: propertyHeadingDefaultEmpty,
      }),
    };
  }

  componentDidMount() {
    const device = this.props.devices.find((device) => device.selected);
    if (device) {
      this.setState({ currentDevice: device });
    } else {
      history.push(buildURI(routingConstants.DASHBOARD));
      return;
    }

    this.updateFirmwareProperties(device);

    //Get ultra device settings
    if (device?.deviceType === PROFILE_SMARTBOX) {
      this.setState({
        loadingSettings: true
      },
      () => {
        getDeviceInstanceFromSN(device.serialNumber).then(deviceInstance => {
          Promise.all([
            new Promise(resolve => {
              deviceInstance.getLedInfo().then((ledInfo) => {
                this.setState({
                  screenBrightness: ledInfo?.screenSettingsBrightness,
                },
                () => {
                  resolve()
                }
              )
              })
            }),
            new Promise(resolve => {
              deviceInstance.getHapticInfo().then((hapticInfo) => {
                this.setState({
                  haptic: hapticInfo?.level,
                },
                () => {
                  resolve()
                }
              )
              })
            }),
            new Promise(resolve => {
              deviceInstance.getBuzzerInfo().then((buzzerInfo) => {
                this.setState({
                  volume: buzzerInfo?.loudness,
                },
                () => {
                  resolve()
                }
              )
              })
            })
          ]).then(() => {
            this.setState({
              loadingSettings: false
            })
          })
        })
      })
    }

    window.document.addEventListener("pairingStart", this.startLoading);
    window.document.addEventListener(
      "pairingSuccess",
      this.toggleLoadingWhenSuccess
    );
    window.document.addEventListener("pairingError", this.stopLoading);
    window.document.addEventListener("searchingError", this.stopLoading);
  }

  _updateFirmwareProperties(device) {
    return new Promise((resolve, reject) => {
      const deviceThing = getDeviceThingFromSN(device.serialNumber);
      const tenantUserId = Commons.generateTenantUserId(
        store.getState().onboardingReducer.userPin
      );
      dashboardServices
        .getFirmwareProperties(tenantUserId, deviceThing?.uuid, VUSE_DEVICE)
        .then((response) => {
          console.log(
            "DeviceSettings getFirmwareProperties response",
            response
          );
          if (response?.data?.data[0]) {
            const deviceProperties = response?.data?.data[0];
            debug(
              `New firmware properties from be : ${JSON.stringify(
                deviceProperties
              )}`
            );
            this.setState(
              {
                deviceProps: {
                  firmwareSource: null,
                  ...deviceProperties,
                },
              },
              () => {
                resolve();
              }
            );
          } else {
            debug(`Unable to read new be firmware properties`);
            resolve();
          }
        })
        .catch(() => {
          debug(`Unable to read new be firmware properties`);
          resolve();
        });
    });
  }

  componentWillUnmount() {
    window.document.removeEventListener("pairingStart", this.startLoading);
    window.document.removeEventListener(
      "pairingSuccess",
      this.toggleLoadingWhenSuccess
    );
    window.document.removeEventListener("pairingError", this.stopLoading);
    window.document.removeEventListener("searchingError", this.stopLoading);
  }

  _toggleLoadingWhenSuccess() {
    const { currentDevice } = this.state;

    this.updateFirmwareProperties(currentDevice).then(() => {
      this.stopLoading();
    });
  }

  _startLoading() {
    this.setState({ loading: true });
  }

  _stopLoading() {
    this.setState({ loading: false });
  }

  checkIsAdvNameSupported(device) {
    isAdvertisingNameSupported(device)
      .then((isSupported) => {
        console.debug(
          "DeviceCustonName - isAdvertisingNameSupported",
          isSupported
        );

        this.setState({
          ...this.state,
          isAdvNameSupported: isSupported,
        });
      })
      .catch((err) => console.debug(err));
  }

  componentDidUpdate(prevProps, prevState) {
    /*if (prevProps.devices !== this.props.devices) {
      const device = this.props.devices.find((device) => device.selected);
      //debug(`New device: ${JSON.stringify(device)}`);
      this.setState({ currentDevice: device });
    }*/

    if (prevState.currentDevice !== this.state.currentDevice) {
      this.checkIsAdvNameSupported(this.state.currentDevice);
    }

    // if (prevState.currentDevice !== this.state.currentDevice) {
    //   if (
    //     isNil(this.state.currentDevice) ||
    //     !isDeviceConnected(this.state.currentDevice)
    //   ) {
    //     history.push(buildURI(routingConstants.DASHBOARD));
    //     return;
    //   }
    // }
    if(prevState.deviceColor !== this.state.deviceColor){
      this._handleSaveDeviceColor()
    }

    if(prevState.ledBrightness !== this.state.ledBrightness){
      this._handleSaveLedBrightness()
    }

    if (!this.state.loadingSettings) {
      if(prevState.screenBrightness !== this.state.screenBrightness){
        this._handleSaveScreenBrightness()
      }

      if(prevState.haptic !== this.state.haptic){
        this._handleSaveHaptic()
      }

      if(prevState.volume !== this.state.volume){
        this._handleSaveVolume()
      }
    }
  }

  _goToDashboard() {
    history.push(buildURI(routingConstants.DASHBOARD));
  }

  _goToManageDevices() {
    const { currentDevice } = this.state;

    const { removeSelected, location } = this.props;

    if (location?.state?.backTo) {
      history.push(buildURI(location.state.backTo));
    } else {
      if (isNil(currentDevice)) {
        history.push(buildURI(routingConstants.MANAGE_DEVICES));
      } else {
        removeSelected(currentDevice, () => {
          history.push(buildURI(routingConstants.MANAGE_DEVICES));
        });
      }
    }
  }

  _goToAdvancedSettings() {
    const { location } = this.props;

    logAnalyticsEvent(AnalyticsEvents.ADVANCED_SETTINGS);
    history.push(buildURI(routingConstants.ADVANCED_SETTINGS), {type: 'slide', isFrom: location?.state?.backTo});
  }

  _goToFirmwareUpgrade() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.UPGRADE_FIRMWARE})
    this.toggleFirmwareUpgradePanel();
  }

  _toggleFirmwareUpgradePanel() {
    this.setState({
      isShownFirmwareUpgradePanel: !this.state.isShownFirmwareUpgradePanel,
    });
  }

  _handleContinue() {
    this.setState(
      {
        ...this.state,
        isSaveSettingsPanelOpen: false,
      },
      () => {
        if (!this.state.saveSettingsError) {
          this.goToManageDevices();
        }
      }
    );
  }

  _handleSaveDeviceColor() {
    const { addOrUpdateDevice } = this.props;
    const { currentDevice, deviceColor } = this.state;

    this.setState({ spinnerLoading: true })
  
    // logAnalyticsEventForDevice(currentDevice, "device_color", {
    //   device_color: deviceColor,
    // });
  
    getDeviceInstanceFromSN(currentDevice.serialNumber).then(
      async (deviceInstance) => {
        if (deviceInstance) {
          let devicePayload = { ...currentDevice };
  
          addOrUpdateDevice(
            new DeviceModel({
              ...devicePayload,
              deviceColor: deviceColor ?? currentDevice?.deviceColor,
            }),
            false,
            () => {
              logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.DEVICE_COLOR, field_value: deviceColor})

              const data = {
                "serialNumber": HMAC_SHA256(
                  currentDevice?.serialNumber,
                  store.getState().onboardingReducer.userPin
                ).toString(),
                "color": deviceColor,
                "timestamp": (new Date()).toISOString()
              }
        
              store.dispatch(userActions.setUserSettings(
                crmUserSettings.DEVICE_COLOR,
                JSON.stringify(data)
              ))
              
              setTimeout(() => {
                this.setState({ spinnerLoading: false })
              }, 300)
            }
          );
        }
      }
    )
    .catch(() => {
      this.setState({ spinnerError: true })
    })
  }  
  
  _handleSaveLedBrightness() {
    const { addOrUpdateDevice } = this.props;
    const { currentDevice, ledBrightness } = this.state;

    this.setState({ spinnerLoading: true })
  
    // logAnalyticsEventForDevice(currentDevice, "led_brightness", {
    //   device_led_level: ledBrightness,
    // });
  
    getDeviceInstanceFromSN(currentDevice.serialNumber).then(
      async (deviceInstance) => {
        if (deviceInstance) {
          let devicePayload = { ...currentDevice };
  
          try {
            console.debug("LED_BRIGHTNESS_UPDATE", ledBrightness);
            await deviceInstance.setLedConfig(ledBrightness);
            console.debug("LED_BRIGHTNESS_UPDATE_SUCCESS");
            devicePayload = {
              ...devicePayload,
              ledInfo: {
                brightness: ledBrightness,
              },
            };

            addOrUpdateDevice(
              new DeviceModel(devicePayload),
              false,
              () => {
                /*const deviceThing = getDeviceThingFromSN(currentDevice.serialNumber);
                const tenantUserId = Commons.generateTenantUserId(
                  store.getState().onboardingReducer.userPin
                );

                setLedBrightnessThing(tenantUserId, deviceThing?.uuid, ledBrightness)*/

                logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.LED_BRIGHTNESS, field_value: ledBrightness})
                this.setState({ spinnerLoading: false })
              }
            );
          } catch (err) {
            console.debug("LED_BRIGHTNESS_UPDATE_FAILURE", err);
            this.setState({ spinnerError: true })
          }
        }
      }
    )
    .catch(() => {
      this.setState({ spinnerError: true })
    })
  }
  
    

  _doDeviceRemoval() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.REMOVE_DEVICE});
    this.setState({ isShownRemoveDevicePanel: true });
  }

  _onEndDeviceRemoval(processEnded) {
    this.setState({ isShownRemoveDevicePanel: false }, () => {
      if (processEnded) {
        history.push(buildURI(routingConstants.DASHBOARD));

        if (this.props.devices.length === 0)
          store.dispatch(
            userActions.setUserSettings(crmUserSettings.DEVICEPAIR, "false")
          );
      }
    });
  }

  hideFirmwareUpdatePanel() {
    this.setState({
      isShownFirmwareUpgradePanel: false,
    });
  }

  _handleSaveScreenBrightness() {
    const { setScreenBrightnessThing } = this.props;
    const { currentDevice, screenBrightness } = this.state;

    this.setState({ spinnerLoading: true })

    const value = screenBrightness >= MIN_SCREEN_BRIGHTNESS ? screenBrightness : MIN_SCREEN_BRIGHTNESS
  
    getDeviceInstanceFromSN(currentDevice.serialNumber).then(
      async (deviceInstance) => {
        if (deviceInstance) {
          try {
            await deviceInstance.setLedConfig({
              //brightness
              screenSettingsBrightness: value
              //powerSaverBrightness
            });

            const deviceThing = getDeviceThingFromSN(currentDevice.serialNumber);
            const tenantUserId = Commons.generateTenantUserId(
              store.getState().onboardingReducer.userPin
            );

            setScreenBrightnessThing(tenantUserId, deviceThing?.uuid, value)

            logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.SCREEN_BRIGHTNESS, field_value: value})
            this.setState({ spinnerLoading: false })
          } catch (err) {
            console.log("SCREEN_BRIGHTNESS_UPDATE_FAILURE", err);
            this.setState({ spinnerError: true })
          }
        }
      }
    )
    .catch(() => {
      this.setState({ spinnerError: true })
    })
  }

  _handleSaveVolume() {
    const { setVolumeThing } = this.props;
    const { currentDevice, volume } = this.state;

    this.setState({ spinnerLoading: true })
  
    getDeviceInstanceFromSN(currentDevice.serialNumber).then(
      async (deviceInstance) => {
        if (deviceInstance) {
          try {
            await deviceInstance.setBuzzerConfig(volume);

            const deviceThing = getDeviceThingFromSN(currentDevice.serialNumber);
            const tenantUserId = Commons.generateTenantUserId(
              store.getState().onboardingReducer.userPin
            );

            setVolumeThing(tenantUserId, deviceThing?.uuid, volume)

            logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.SOUND_VOLUME, field_value: volume})
            this.setState({ spinnerLoading: false })
          } catch (err) {
            console.log("VOLUME_UPDATE_FAILURE", err);
            this.setState({ spinnerError: true })
          }
        }
      }
    )
    .catch(() => {
      this.setState({ spinnerError: true })
    })
  }

  _handleSaveHaptic() {
    const { setHapticThing } = this.props;
    const { currentDevice, haptic } = this.state;

    this.setState({ spinnerLoading: true })
  
    getDeviceInstanceFromSN(currentDevice.serialNumber).then(
      async (deviceInstance) => {
        if (deviceInstance) {
          try {
            await deviceInstance.setHapticInfo(haptic);

            const deviceThing = getDeviceThingFromSN(currentDevice.serialNumber);
            const tenantUserId = Commons.generateTenantUserId(
              store.getState().onboardingReducer.userPin
            );

            setHapticThing(tenantUserId, deviceThing?.uuid, haptic)

            logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.HAPTIC_INTENSITY, field_value: haptic})
            this.setState({ spinnerLoading: false })
          } catch (err) {
            console.debug("HAPTIC_UPDATE_FAILURE", err);
            this.setState({ spinnerError: true })
          }
        }
      }
    )
    .catch(() => {
      this.setState({ spinnerError: true })
    })
  }

  render() {
    const {
      currentDevice,
      deviceName,
      deviceColor,
      ledBrightness,
      isShownFirmwareUpgradePanel,
      deviceProps,
      isShownRemoveDevicePanel,
      isSaveSettingsPanelOpen,
      screenBrightness,
      haptic,
      volume
    } = this.state;

    const {
      location
    } = this.props;

    const firmwareVersion = currentDevice?.deviceInfo?.firmwareVersion;
    const currentDeviceName = deviceName ?? (currentDevice?.deviceCustomName ?? currentDevice?.deviceInfo?.deviceName)
    const currentDeviceColor = deviceColor ?? currentDevice?.deviceColor;
    const currentDeviceType = currentDevice?.deviceType;
    const currentLedBrightness =
      ledBrightness ?? currentDevice?.ledInfo?.brightness;

    const disableAll =
      currentDevice === null ||
      currentDevice === undefined ||
      (currentDevice && !isSynchronized(currentDevice));
    return (
      <React.Fragment>
        {this.state.loading && (
          <div className="connecting-overlay">
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="device-settings">
          <Header
            leftButton={!this.props?.history?.location?.state?.close ? {
              icon: <span className="bat-icon-back" />,
              onClick: this.goToManageDevices,
              } : null
            }
            rightButton={this.props?.history?.location?.state?.close ? {
              icon: <span className="bat-icon-close" />,
              onClick: this.goToManageDevices
            } : null
            }
          >
            {this.dictionary.DEVICE_SETT_HEADER}
          </Header>
          <div className="device-settings-form">
            <DeviceCustomName
              device={currentDevice}
              setDeviceName={(deviceName) => this.setState({ deviceName })}
              deviceName={currentDeviceName}
              placeholder={
                currentDevice?.deviceInfo?.advertisingName ||
                currentDevice?.deviceInfo?.deviceName
              }
              title={this.dictionary.DEVICE_SETT_DEVICE_NAME}
              isAdvNameSupported={this.state.isAdvNameSupported}
              alertTitle={this.dictionary.DEVICE_SETT_ADV_NAME_ALERT_TITLE}
              alertSubtitle={
                this.dictionary.DEVICE_SETT_ADV_NAME_ALERT_SUBTITLE
              }
              emptyError={this.dictionary.DEVICE_SETT_ADV_NAME_EMPTY_ERROR}
              charactersError={
                this.dictionary.DEVICE_SETT_ADV_NAME_CHARACTERS_ERROR
              }
              showEditIcon={!disableAll}
              showMessages={false}
              disabled={disableAll}
              onClick={() => {history.push(buildURI(routingConstants.DEVICE_NAME), {type: 'slide', isFrom: location?.state?.backTo}); logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NAME})}}
            />
          </div>
          <div className="device-settings-cta">
            <DeviceCustomColor
              setDeviceColor={(deviceColor) => this.setState({ deviceColor })}
              deviceType={currentDeviceType}
              deviceColor={currentDeviceColor}
              title={this.dictionary.DEVICE_SETT_DEVICE_COLOUR}
              disabled={disableAll}
            />
          </div>
          { getVapingServiceSupport(currentDeviceType, 'LED_BRIGHTNESS') &&
          <DeviceLedBrightness
            setLedBrightness={(brightness) =>
              {this.setState({ ledBrightness: brightness })}
            }
            ledBrightness={currentLedBrightness}
            title={this.dictionary.DEVICE_SETT_LED_BRIGHTNESS}
            disabled={disableAll}
          />
          }
          { getVapingServiceSupport(currentDeviceType, 'SCREEN_BRIGHTNESS') &&
          <DeviceScreenBrightness
            setScreenBrightness={(screenBrightness) =>
              {this.setState({ screenBrightness: screenBrightness })}
            }
            screenBrightness={screenBrightness}
            title={this.dictionary.DEVICE_SETT_BRIGHTNESS}
            disabled={disableAll}
            minValue={MIN_SCREEN_BRIGHTNESS}
          />
          }

          { getVapingServiceSupport(currentDeviceType, 'HAPTIC_INTENSITY') &&
          <DeviceHaptic
            setHaptic={(haptic) =>
              {this.setState({ haptic: haptic })}
            }
            haptic={haptic}
            title={this.dictionary.DEVICE_SETT_HAPTIC_ALERTS}
            labels={{
              low: this.dictionary.DEVICE_SETT_SOFT,
              medium: this.dictionary.DEVICE_SETT_MEDIUM,
              high: this.dictionary.DEVICE_SETT_STRONG
            }}
            disabled={disableAll}
          />
          }

          { getVapingServiceSupport(currentDeviceType, 'SOUND_VOLUME') &&
          <DeviceVolume
            setVolume={(volume) =>
              {this.setState({ volume: volume })}
            }
            volume={volume}
            title={this.dictionary.DEVICE_SETT_SOUND_ALERT}
            disabled={disableAll}
          />
          }

          <div className="device-settings-cta">
            <div className="d-grid gap-2">
              <div style={{ fontSize: "0.75rem" }}>
                <label className="page-label device-firmware-title" htmlFor="firmware">
                  {this.dictionary.DEVICE_SETT_FIRMWARE}
                </label>{" "}
                V{firmwareVersion}
              </div>
              {deviceProps?.firmwareSource !== null &&
              deviceProps?.firmwareSource !== undefined &&
              deviceProps?.firmwareSource !== "" ? (
                <button
                  className="btn btn-warning text-uppercase btn-firmware"
                  disabled={disableAll}
                  onClick={this.goToFirmwareUpgrade}
                >
                  {this.dictionary.DEVICE_SETT_BTN_FIRMWARE_UPDATE_0_ctaLabel}{" "}
                  <span className="bat-icon-download ms-3"></span>
                </button>
              ) : (
                <button className="btn btn-warning text-uppercase btn-firmware disabled">
                  {
                    this.dictionary
                      .DEVICE_SETT_BTN_FIRMWARE_UP_TO_DATE_0_ctaLabel
                  }{" "}
                  <span className="bat-icon-download ms-3"></span>
                </button>
              )}
            </div>
          </div>

          <div className="device-settings-cta pb-5">
            <div className="d-grid gap-2">
              <label className="page-label">
                {this.dictionary.DEVICE_SETT_REMOVE_DEVICE_TITLE}
              </label>
              <div className="remove-device-warning">
                <span>{this.dictionary.DEVICE_SETT_REMOVE_DEVICE_BODY}</span>
                <div className="d-grid">
                  <button
                    style={{ zIndex: 3 }}
                    className="btn btn-dark text-uppercase"
                    onClick={this.doDeviceRemoval}
                  >
                    {this.dictionary.DEVICE_SETT_BTN_REMOVE_DEVICE_0_ctaLabel}
                  </button>
                </div>
              </div>

              <button
                className="btn btn-dark btn-outline-secondary text-uppercase"
                style={{ zIndex: 3 }}
                onClick={this.goToAdvancedSettings}
                disabled={disableAll}
              >
                {this.dictionary.DEVICE_SETT_ADVANCED_SETTINGS_HEADER}
              </button>
            </div>
          </div>
          <div className="page-linear-gradient-bg" style={{ zIndex: -1 }}></div>
        </div>

        {isShownFirmwareUpgradePanel && (
          <FirmwareUpdate
            show={isShownFirmwareUpgradePanel}
            onClose={this.hideFirmwareUpdatePanel}
          />
        )}

        <CustomSlidingPanel
          from="bottom"
          isOpen={isSaveSettingsPanelOpen}
          overlayClassName={"sliding-common-wrapper"}
          className="onboarding-panel mid-height-panel"
          title={this.dictionary.DEVICE_SETT_HEADER}
          backdropClose={false}
        >
          <div className="process-data-container">
            <div className="process-data-title">
              {this.state.saveSettingsError ||
                this.dictionary.DEVICE_SETT_ADV_NAME_DEVICE_SETTINGS_SUCCESS}
            </div>
            <div className="process-data-image">
              <span
                className={cx({
                  "process-end-icon": !this.state.saveSettingsError,
                  "process-removal": this.state.saveSettingsError,
                })}
              />
            </div>
            <PageSelectorCTA sticky={false} className="mt-auto ps-0 pe-0">
              <div className="d-grid gap-2">
                <button
                  className="btn btn-primary text-uppercase"
                  onClick={this.handleContinue}
                >
                  {this.dictionary.DEVICE_SETT_ADV_NAME_BTN_CONTINUE_0_ctaLabel}
                </button>
              </div>
            </PageSelectorCTA>
          </div>
        </CustomSlidingPanel>

        {/* <CustomSlidingPanel
          from="bottom"
          isOpen={isShownFirmwareUpgradePanel}
          overlayClassName={"sliding-common-wrapper"}
          className="onboarding-panel mid-height-panel"
          title={this.dictionary.FW_UPDATE_HEADER}
          backdropClose={false}
          onClose={this.toggleFirmwareUpgradePanel}
        >
          <FirmwareUpdateProgress
            device={currentDevice}
            nextStep={this.toggleFirmwareUpgradePanel}
            deviceProperties={deviceProps}
            fromSettings={true}
          />
        </CustomSlidingPanel> */}

        <RemoveDevice
          device={currentDevice}
          devices={this.props.devices}
          isOpen={isShownRemoveDevicePanel}
          handleProcessEnd={this.onEndDeviceRemoval}
        />

        <SpinnerModal
          show={this.state.spinnerLoading}
          error={this.state.spinnerError}
          onForceClose={() => this.setState({ spinnerLoading: false, spinnerError: false })}
          goOnMoreMenu={ !this.props?.history?.location?.state?.close }
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    devices: state.deviceReducer.devices,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addOrUpdateDevice: (devicePayload, addOnTop, callBack) => {
      dispatch(
        deviceActions.addOrUpdateDevice(
          devicePayload.toObj(),
          addOnTop,
          callBack
        )
      );
    },
    removeSelected: (currentDevice, callback) => {
      dispatch(deviceActions.removeSelected(currentDevice, callback));
    },
    setUserSettings: (settingName, value, successCb, failureCb) => {
      dispatch(
        userActions.setUserSettings(settingName, value, successCb, failureCb)
      );
    },
    /*setLedBrightnessThing: (tenantUserId, uuid, value, successCb, failureCb) => {
      dispatch(
        deviceActions.setLedBrightness(
          tenantUserId,
          uuid,
          value,
          successCb,
          failureCb
        )
      );
    },*/
    setScreenBrightnessThing: (tenantUserId, uuid, value, successCb, failureCb) => {
      dispatch(
        deviceActions.setScreenBrightness(
          tenantUserId,
          uuid,
          value,
          successCb,
          failureCb
        )
      );
    },
    setHapticThing: (tenantUserId, uuid, value, successCb, failureCb) => {
      dispatch(
        deviceActions.setHaptic(
          tenantUserId,
          uuid,
          value,
          successCb,
          failureCb
        )
      );
    },
    setVolumeThing: (tenantUserId, uuid, value, successCb, failureCb) => {
      dispatch(
        deviceActions.setVolume(
          tenantUserId,
          uuid,
          value,
          successCb,
          failureCb
        )
      );
    },
  };
}

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceSettings);
export { connectedComponent as DeviceSettings };
